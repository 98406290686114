import adapter from '../adapter';
export default function contextFactory() {
  var _adapter$getRuntime = adapter.getRuntime(),
    createContext = _adapter$getRuntime.createContext;
  var context = window.__appContext;
  if (!context) {
    context = createContext({});
    window.__appContext = context;
  }
  return context;
}