// eslint-disable-next-line no-shadow
export var IPublicEnumDragObjectType;

/**
 * @deprecated use IPublicEnumDragObjectType instead
 */
(function (IPublicEnumDragObjectType) {
  IPublicEnumDragObjectType["Node"] = "node";
  IPublicEnumDragObjectType["NodeData"] = "nodedata";
})(IPublicEnumDragObjectType || (IPublicEnumDragObjectType = {}));
export var DragObjectType;
(function (DragObjectType) {
  DragObjectType["Node"] = "node";
  DragObjectType["NodeData"] = "nodedata";
})(DragObjectType || (DragObjectType = {}));