// eslint-disable-next-line no-shadow
export var IPublicTypeLocationDetailType;

/**
 * @deprecated please use IPublicTypeLocationDetailType
 */
(function (IPublicTypeLocationDetailType) {
  IPublicTypeLocationDetailType["Children"] = "Children";
  IPublicTypeLocationDetailType["Prop"] = "Prop";
})(IPublicTypeLocationDetailType || (IPublicTypeLocationDetailType = {}));
export var LocationDetailType;
(function (LocationDetailType) {
  LocationDetailType["Children"] = "Children";
  LocationDetailType["Prop"] = "Prop";
})(LocationDetailType || (LocationDetailType = {}));