export var IPublicEnumTransformStage;
/**
 * @deprecated use IPublicEnumTransformStage instead
 */
(function (IPublicEnumTransformStage) {
  IPublicEnumTransformStage["Render"] = "render";
  IPublicEnumTransformStage["Serilize"] = "serilize";
  IPublicEnumTransformStage["Save"] = "save";
  IPublicEnumTransformStage["Clone"] = "clone";
  IPublicEnumTransformStage["Init"] = "init";
  IPublicEnumTransformStage["Upgrade"] = "upgrade";
})(IPublicEnumTransformStage || (IPublicEnumTransformStage = {}));
export var TransformStage;
(function (TransformStage) {
  TransformStage["Render"] = "render";
  TransformStage["Serilize"] = "serilize";
  TransformStage["Save"] = "save";
  TransformStage["Clone"] = "clone";
  TransformStage["Init"] = "init";
  TransformStage["Upgrade"] = "upgrade";
})(TransformStage || (TransformStage = {}));