// 仅使用类型

export var getClosestNode = function getClosestNode(node, until) {
  if (!node) {
    return undefined;
  }
  if (until(node)) {
    return node;
  } else {
    // @ts-ignore
    return getClosestNode(node.parent, until);
  }
};

/**
 * 判断节点是否可被点击
 * @param {Node} node 节点
 * @param {unknown} e 点击事件
 * @returns {boolean} 是否可点击，true表示可点击
 */
export function canClickNode(node, e) {
  var _node$componentMeta, _node$componentMeta$a, _node$componentMeta$a2;
  var onClickHook = (_node$componentMeta = node.componentMeta) === null || _node$componentMeta === void 0 ? void 0 : (_node$componentMeta$a = _node$componentMeta.advanced) === null || _node$componentMeta$a === void 0 ? void 0 : (_node$componentMeta$a2 = _node$componentMeta$a.callbacks) === null || _node$componentMeta$a2 === void 0 ? void 0 : _node$componentMeta$a2.onClickHook;
  var canClick = typeof onClickHook === 'function' ? onClickHook(e, node) : true;
  return canClick;
}