import { IPublicEnumTransitionType } from '@alilc/lowcode-types';
import { runInAction } from 'mobx';
import EventEmitter from 'events';
var TransactionManager = function TransactionManager() {
  var _this = this;
  this.emitter = new EventEmitter();
  this.executeTransaction = function (fn, type) {
    if (type === void 0) {
      type = IPublicEnumTransitionType.REPAINT;
    }
    _this.emitter.emit("[" + type + "]startTransaction");
    runInAction(fn);
    _this.emitter.emit("[" + type + "]endTransaction");
  };
  this.onStartTransaction = function (fn, type) {
    if (type === void 0) {
      type = IPublicEnumTransitionType.REPAINT;
    }
    _this.emitter.on("[" + type + "]startTransaction", fn);
    return function () {
      _this.emitter.off("[" + type + "]startTransaction", fn);
    };
  };
  this.onEndTransaction = function (fn, type) {
    if (type === void 0) {
      type = IPublicEnumTransitionType.REPAINT;
    }
    _this.emitter.on("[" + type + "]endTransaction", fn);
    return function () {
      _this.emitter.off("[" + type + "]endTransaction", fn);
    };
  };
};
export var transactionManager = new TransactionManager();