// eslint-disable-next-line no-shadow
export var IPublicEnumTransitionType;

/**
 * @deprecated use IPublicEnumTransitionType instead
 */
(function (IPublicEnumTransitionType) {
  IPublicEnumTransitionType[IPublicEnumTransitionType["REPAINT"] = 0] = "REPAINT";
})(IPublicEnumTransitionType || (IPublicEnumTransitionType = {}));
export var TransitionType;
(function (TransitionType) {
  TransitionType[TransitionType["REPAINT"] = 0] = "REPAINT";
})(TransitionType || (TransitionType = {}));