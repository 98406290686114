// eslint-disable-next-line no-shadow
export var IPublicEnumPropValueChangedType;

/**
 * @deprecated please use IPublicEnumPropValueChangedType
 */
(function (IPublicEnumPropValueChangedType) {
  IPublicEnumPropValueChangedType["SET_VALUE"] = "SET_VALUE";
  IPublicEnumPropValueChangedType["SUB_VALUE_CHANGE"] = "SUB_VALUE_CHANGE";
})(IPublicEnumPropValueChangedType || (IPublicEnumPropValueChangedType = {}));
export var PROP_VALUE_CHANGED_TYPE;
(function (PROP_VALUE_CHANGED_TYPE) {
  PROP_VALUE_CHANGED_TYPE["SET_VALUE"] = "SET_VALUE";
  PROP_VALUE_CHANGED_TYPE["SUB_VALUE_CHANGE"] = "SUB_VALUE_CHANGE";
})(PROP_VALUE_CHANGED_TYPE || (PROP_VALUE_CHANGED_TYPE = {}));